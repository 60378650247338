import { IPage } from '@standardTypes';

import { Layout } from '@components/layout';
import { PrivacySection } from '@components/molecules';

interface IFaqPage {
  pageContext: {
    page: IPage;
    translationUrls: Record<string, string>;
  };
}

const PrivacyPolicy = ({ pageContext }: IFaqPage): JSX.Element => {
  const { page, translationUrls } = pageContext;
  return (
    <Layout {...page} translationUrls={translationUrls}>
      <PrivacySection
        pageContent={page.content || ''}
        pageTitle={page.title || ''}
      />
    </Layout>
  );
};

export default PrivacyPolicy;
